$primary-text: #003866;

.Blog-title {
    font-size: 2.75em;
}

.Blog-date {
    font-size: 1em;
    font-style: italic;
}

.Blog-content {
    font-size: 1em;
}

.Blog-tag {
    display: inline-block;
    padding: 0.25em;
    border-radius: 0.5em;
    font-size: 1em;
    border: 0.1em solid $primary-text;
}

.Blog {
    color: $primary-text
}


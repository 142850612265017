$primary-text: #003866;

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .row:after {/* must need this or the parent container will be 0 */
    content: "";
    display: table;
    clear: both;
  }
  
  .title-row {
    font-size: 3.75em; /* 60px/16=2.5em */
    font-weight: 900;
    color: $primary-text;
  }
  
  .column {
    float: left;
  }
  
  .column-nav {
    margin-top: 0.5em;
    width: 10%
  }
  
  .route-link {
    margin-bottom: 0.75rem;
    text-align: center;
    border-style: solid;
    padding: 0.25rem;
    font-size: 1.25rem; /* 14px/16=0.875em */
    text-decoration: none;
    font-weight: 500;
    overflow: hidden;
  }
  
  .column-main {
    margin-left: 1em;
    margin-right: 1em;
    width: 80%
  }
  
  
  .footer {
    color: $primary-text;
    padding: 1rem;
    text-align: center;
  }
  
  
  @media screen and (max-width:700px) {
    .column {
      width: 100%;
    }
    .route-link {
      margin-bottom: 0.75rem;
      text-align: center;
      border-style: solid;
      padding: 0.25rem;
      font-size: 1.25rem; /* 14px/16=0.875em */
      text-decoration: none;
      font-weight: 500;
    }
  }
  
  a {
    color: $primary-text;
  }